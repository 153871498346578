.header {
  background-color: #F09000;
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.15);
  z-index: 2;
  padding-left: 2rem;
  padding-right: 2rem;
  font-family: "Roboto";
  color: #001130;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.44px;
  line-height: 16px;
  white-space: nowrap;
  display: flex;
  flex: 1 100%;
  justify-content: space-between;
  min-height: 75px !important;
  position: relative;
}

.header-logo {
  /* height: 2.5rem; */
  height: 2.5rem;
  width: 8rem;
  padding:5px;
  object-fit: contain;
  /* padding-right: 20px; */
  transition: transform 0.8s;
}

.header-logo:hover {
  transform: scale(1.1);
}

.nav-menu {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  height: 75px;
  gap: 1rem;
  position: relative;
}

.nav-links {
  color: white;
  text-decoration: none;
  position: relative;
  font-family: 'Roboto';
  height: 75px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 600;
  font-size: 15px;
}

.nav-link-title {
  font-size: 15px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  text-align: center;
}

.nav-links:hover {
  transition: all 0.2s ease-out;
}

.nav-links:hover .nav-link-title{
  border-bottom: 4px solid #FAD37F;
}

.link {
  text-decoration: none;
  color: #6B6B6B;
  font-family: Roboto;
}

.link:hover {
  color: #f09000;
}

.link-active {
  color: #f09000;
  text-decoration: underline;
  text-decoration-color: #f09000;
}

.fa-bars {
  color: #001130;
}

.fa-bars:hover {
  /* color: #f09000; */
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.get-started-div {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
}

.get-started-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.5rem;
  width: 4rem;
  border-radius: 30px;
  border: 2px solid #f0a12b;
  box-shadow: 1px 2px 7px 0 rgba(0, 0, 0, 0.15);
  font-family: "Roboto", sans-serif;
  font-size: 0.5rem;
  font-weight: bold;
  letter-spacing: 0.47px;
  line-height: 15px;
  transition: transform 0.5s;
  /* color: #f09000; */
  /* color: white; */
  margin-right: 0.5rem;
}
.language-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.5rem;
  /* width: 4rem; */
  border-radius: 30px;
  /* border: 2px solid #f0a12b; */
  /* box-shadow: 1px 2px 7px 0 rgba(0, 0, 0, 0.15); */
  font-family: "Roboto", sans-serif;
  /* font-size: 0.6rem; */
  font-weight: bold;
  letter-spacing: 0.47px;
  line-height: 15px;
  transition: transform 0.5s;
  color: #f09000;
  margin-right: 0.5rem;
}

.contact-us-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.5rem;
  width: 5rem;
  border-radius: 30px;
  background-color: #f09000;
  box-shadow: 1px 2px 7px 0 rgba(0, 0, 0, 0.15);
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-size: 0.5rem;
  font-weight: bold;
  letter-spacing: 0.47px;
  line-height: 15px;
  transition: transform 0.5s;
  margin-right: 0.5rem;
}

.get-started-header:hover,
.contact-us-header:hover {
  transform: scale(1.1);
}

.dropdown-button:hover {
  /* color: #f09000; */
}

.dropdown-list {
  display: none;
  flex-direction: column;
  position: absolute;
  background-color: #f6f6ff;
  padding: 0;
  box-shadow: 1px 2px 7px 0 rgba(0, 0, 0, 0.15);
  top: 100%;
  left: 0;
}

.dropdown-list > li {
  padding: 0.70rem 0.75rem;
  width: 100%;
  font-size: 0.7rem;
}

.dropdown-list > li:hover {
  background-color: #fef7ec;
}

.nav-links:hover > .dropdown-list {
  display: block;
}

.cursor-pointer {
  cursor: pointer;
}

.language-list {
  display: none;
  flex-direction: column;
  position: absolute;
  background-color: #f6f6ff;
  padding: 0;
  border-radius: 10px;
  box-shadow: 1px 2px 7px 0 rgba(0, 0, 0, 0.15);
}

.language-list > li {
  padding: 10px;
  width: 100%;
  font-size: 0.7rem;
}

.language-list > li:hover {
  background-color: #fef7ec;
}

.header-business-types {
  display: none;
  flex-direction: column;
  position: absolute;
  background-color: #f6f6ff;
  padding: 0;
  box-shadow: 1px 2px 7px 0 rgba(0, 0, 0, 0.15);
  top: 100%;
  left: -290%;
}

.header-business-types-title:hover > .header-business-types {
  display: flex;
  width: 100vw;
}

@media (min-width: 1251px) and (max-width: 1292px) {
  .header-business-types-title:hover > .header-business-types {
    left: -280%;
    width: 97vw;
  }
}

@media (min-width: 1024px) and (max-width: 1250px) {
  .header-business-types-title:hover > .header-business-types {
    left: -250%;
    width: 94vw;
  }
}

@media screen and (max-width: 1250px) {
  .header {
    padding: 0;
  }

  .nav-menu {
    gap: 0.5rem;
  }
}

@media screen and (max-width: 1023px) {
  .header {
    /* padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 4rem;
    padding-right: 4rem; */
  }

  .nav-menu {
    display: none;
  }

  .nav-menu-active {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    background-color: #f6f6ff;
    left: 0;
    opacity: 1;
    transition: all 2s ease;
    width: 100%;
  }

  .nav-links {
    text-align: center;
    padding: 0.5rem;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    transition: all 0.5s ease;
  }

  .nav-links:hover {
    border-radius: 0;
  }

  .header-logo {
    position: absolute;
    top: 0;
    left: 0;
    height: 4rem;
    width: 8rem;
    padding-left: 1rem;
    display: none;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #001130;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0.6rem;
    margin: 0.1rem auto;
    border-radius: 4px;
    text-decoration: none;
    color: #ffffff;
    font-size: 0.6rem;
  }

  .nav-links-mobile:hover {
    background: #fff9f1;
    transition: 250ms;
  }

  .get-started-div {
    display: none;
  }

  .get-started {
    display: none;
  }

  .nav-links:hover > .dropdown-list {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    margin-bottom: 1rem;
    margin-top: 0;
    top: 1rem;
  }
}
