.FAQ {
  display: flex;
  flex-direction: column;
  padding: 3rem 4rem;
}

.FAQ > h5 {
  text-align: left;
  color: #f09000;
  font-family: "Sansation", sans-serif;
  letter-spacing: 0;
  line-height: 25px;
}

.faq-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.faq-box {
  width: 33%;
  height: 12rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
}

.faq-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.15);
  text-decoration: none;
}

.faq-content-image {
  width: 5rem;
  height: 5rem;
  box-sizing: border-box;
  border: 1px solid #e0e0e0;
  background-color: rgb(223, 222, 222);
  border-radius: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.faq-content-image > img {
  width: 60%;
  height: 60%;
  object-fit: fill;
}

.faq-content > h6 {
  color: #001130;
  font-family: "Sansation", sans-serif;
  letter-spacing: 0;
  line-height: 27px;
  text-align: center;
  padding-top: 0.25rem;
}

@media screen and (max-width: 600px) {
  .FAQ {
    padding: 1rem 2rem;
  }

  .faq-box {
    width: 100%;
    height: 10rem;
  }
}
