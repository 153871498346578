.download-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  /* width: 65%; */
}

button{
  border: 0;
  background: transparent;
}

.qr-button {
  text-align: center;
  /* width: 5.5rem; */
  /* border-radius: 30px; */
  /* border: 2px solid #f0a12b; */
  box-shadow: 1px 2px 7px 0 rgba(0, 0, 0, 0.15);
  font-family: "Roboto", sans-serif;
  font-size: 0.65rem;
  /* font-weight: bold; */
  letter-spacing: 0.47px;
  line-height: 15px;
  transition: transform 0.5s;
	/* color: #f09000; */
  /* background: white;  */
}

.qr-button:hover {
  transform: scale(1.1);
}

@media screen and (max-width: 1160px) {
  .download-links {
    justify-content: center;
    width: 100%;

  }

}
