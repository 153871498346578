.product-banner {
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
  max-width: 100%;
}

.product-banner-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2rem 4rem;
  width: 50%;
}

.banner-img {
  display: flex;
  width: 50%;
}

.banner-img > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.product-tagline {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
  margin-right: 0;
  margin-left: 0;
  width: 72%;
}

.product-tagline-desc {
  height: 25px;
  width: 394px;
  color: #f09000;
  font-family: "Sansation", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: left;
  text-align: left;
  white-space: nowrap;
}

.product-tagline-desc > span {
  color: #001130;
}

@media screen and (max-width: 1024px) {
  .product-banner {
    flex-direction: column-reverse;
  }

  .product-banner-info {
    width: 100%;
    padding: 1rem 2rem;
  }

  .banner-img {
    width: 100%;
  }
}
