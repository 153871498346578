.custom-modal-style {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    max-width: 100% !important;
    max-height: 80vh;
    background-color: none;
  }
  
  video {
    max-width: 100%;
    height: auto;
  }
  
  .modalCont {
    position: relative;
    /* padding-bottom: 56.25%; */
    /* 16:9 */
    padding-top: 0px;
    /* height: 0; */
  }
  
  .modalCont iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  