.simple-form {
  height: 2.8rem;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  border: 1px solid #e7e7e7;
  border-radius: 25.5px;
  background-color: #ffffff;
  box-shadow: 1px 5px 20px 5px rgba(0, 0, 0, 0.15);
  align-items: center;
  padding-right: 0.12rem;
  margin-bottom: 1rem;
}

input {
  border: none;
  border-radius: 25.5px;
  padding-left: 1rem;
  width: 70%;
}

input::placeholder {
  font-size: 0.7rem;
  text-align: left;
}

input:focus {
  outline: none;
}

.form-btn {
  height: 2.3rem;
  width: 30%;
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 0.6rem;
  letter-spacing: 0;
  line-height: 14px;
  text-align: center;
  border-radius: 25.5px;
  background-color: #f09000;
  border-color: #f09000;
  border-style: solid;
}

@media screen and (max-width: 960px) {
  input {
    width: 60%;
  }

  input::placeholder {
    font-size: 0.8rem;
    text-align: left;
  }

  .form-btn {
    width: 40%;
    font-size: 0.7rem;
  }
}
