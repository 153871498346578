.topics {
  display: flex;
  flex-direction: column;
  padding: 3rem 4rem;
}

.topics > h5 {
  text-align: left;
  color: #f09000;
  font-family: "Sansation", sans-serif;
  letter-spacing: 0;
  line-height: 25px;
}

.topics-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.topic-box {
  width: 12rem;
  height: 12rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.15);
  text-decoration: none;
}

.topic-box-image {
  width: 5rem;
  height: 5rem;
  box-sizing: border-box;
  border: 1px solid #e0e0e0;
  background-color: #ffffff;
  border-radius: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.topic-box-image > img {
  width: 60%;
  height: 60%;
  object-fit: fill;
}

.content > h6 {
  color: #001130;
  font-family: "Sansation", sans-serif;
  letter-spacing: 0;
  line-height: 27px;
  text-align: center;
  padding-top: 0.25rem;
}

@media screen and (max-width: 500px) {
  .topics {
    padding: 1.5rem 2rem;
  }

  .topic-box {
    width: 9rem;
    height: 9rem;
  }

  .topic-box-image {
    width: 3rem;
    height: 3rem;
  }
}
