.footer {
  background-color: #f09000;
  /* border-radius: 60px 0 0 0; */
  /* z-index: 100; */
  z-index: 0;
  font-family: "Roboto", sans-serif;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  flex: 1 100%;
  margin-bottom: 0;
  max-width: 100%;
}

.download {
  background-color: #f09000;
  display: flex;
  flex-direction: row;
  margin: 0;
  padding-top: 2rem;
  padding-left: 4rem;
  padding-right: 4rem;
  max-width: 100%;
}

@media screen and (max-width: 650px) {
  .download {
    padding-top: 1.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-wrap: wrap;
  }
}

.download-info {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 50%;
}

@media screen and (max-width: 550px) {
  .download-info {
    width: 100%;
    margin-top: 0;
    padding-top: 0;
  }
}

.download-info > h1 {
  color: #ffffff;
  font-size: 2rem;
}

@media screen and (max-width: 550px) {
  .download-info > h1 {
    margin: 0;
    padding-top: 1rem;
    line-height: 2.5rem;
    text-align: start;
    padding-bottom: 1rem;
  }
}

.download-info > p {
  color: #ffffff;
  font-size: 0.8rem;
}

@media screen and (max-width: 550px) {
  .download-info > p {
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }
}

.download-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.download-img > img {
  width: 60%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 1000px) {
  .download-img > img {
    width: 95%;
    height: 100%;
    object-fit: cover;
  }
}

@media screen and (max-width: 550px) {
  .download-img {
    width: 80%;
    justify-content: flex-end;
  }

  .download-img > img {
    max-width: 95%;
    object-fit: cover;
  }
}

.links {
  background-color: #1b2b33;
  /* border-radius: 60px 0 0 0; */
  display: flex;
  flex-direction: column;
  margin: 0;
  padding-top: 3rem;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-bottom: 2rem;
  max-width: 100%;
}

@media screen and (max-width: 500px) {
  .links {
    flex-wrap: wrap;
    padding: 1.5rem 2rem;
  }
}

.footer-link-groups {
  display: flex;
  flex-direction: row;
  margin-bottom: 3rem;
  justify-content: space-between;
  max-width: 100%;
}

@media screen and (max-width: 960px) {
  .footer-link-groups {
    flex-wrap: wrap;
    margin-bottom: 1.5rem;
  }
}

.footer-right {
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 500px) {
  .footer-right {
    flex-wrap: wrap;
  }
}

.footer-diglog-desc,
.footer-social,
.footer-the-app,
.footer-helpful-links {
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
  align-items: flex-start;
}

@media screen and (max-width: 650px) {
  .footer-diglog-desc {
    margin: 1rem;
  }
  .footer-the-app,
  .footer-helpful-links {
    width: 50%;
    margin: 0;
    padding: 0.25rem;
    align-items: flex-start;
  }

  .footer-social {
    width: 100%;
    margin-top: 2rem;
    margin-right: 0;
  }
}

.footer-logo,
.social-header,
.the-app-header,
.helpful-links-header {
  color: #ffffff;
  font-family: "Sansation", sans-serif;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  margin-bottom: 2rem;
  white-space: nowrap;
}

@media screen and (max-width: 500px) {
  .footer-logo,
  .social-header,
  .the-app-header,
  .helpful-links-header {
    margin-bottom: 1rem;
    font-size: 1rem;
  }
}

.social-icons {
  display: flex;
  flex-direction: row;
}

.icon {
  height: 2rem;
  width: 2rem;
}

.icon > img {
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 500px) {
  .social-icons {
    justify-content: space-between;
    width: 75%;
  }
}

.icon:not(:last-child) {
  margin-right: 15px;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

ul > li {
  white-space: wrap;
}

.footer-option-links {
  color: #ffffff;
  font-family: "Roboto";
  font-size: 13px;
  letter-spacing: 0;
  line-height: 25px;
}

.diglog-desc {
  color: #ffffff;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0.27px;
  text-align: left;
}

.footer-misc {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

@media screen and (max-width: 500px) {
  .footer-misc {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.copyright {
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.33px;
  line-height: 16px;
  display: flex;
  align-items: flex-end;
}

.copyright > p {
  margin-bottom: 1rem;
  color: #ffffff;
}

.back-to-top {
  box-sizing: border-box;
  height: 47px;
  width: 165px;
  color: #ffffff;
  text-decoration: none;
  border: 1px solid #ffffff;
  border-radius: 30px;
  box-shadow: 1px 2px 7px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.65rem;
}

.arrow {
  height: 12px;
  width: 7.07px;
  transform: rotate(270deg);
  background-color: #00215d;
  color: #ffffff;
}
