@import url("https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i,900,900i");
@import url("https://fonts.googleapis.com/css?family=Raleway:300,300i,400,400i,500,500i,700,700i,900,900i");
/* @import url("https://db.onlinewebfonts.com/c/00370d92f1b404768d34ac7dc664289e?family=Sansation");
@font-face {
  font-family: "Sansation";
  src: url("https://db.onlinewebfonts.com/t/00370d92f1b404768d34ac7dc664289e.eot");
  src: url("https://db.onlinewebfonts.com/t/00370d92f1b404768d34ac7dc664289e.eot?#iefix")
      format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/00370d92f1b404768d34ac7dc664289e.woff2")
      format("woff2"),
    url("https://db.onlinewebfonts.com/t/00370d92f1b404768d34ac7dc664289e.woff")
      format("woff"),
    url("https://db.onlinewebfonts.com/t/00370d92f1b404768d34ac7dc664289e.ttf")
      format("truetype"),
    url("https://db.onlinewebfonts.com/t/00370d92f1b404768d34ac7dc664289e.svg#Sansation")
      format("svg");
} */

.App {
  display: flex;
  flex-direction: column;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bg-white {
  background-color: #ffffff;
}

.bg-light-yellow {
  background-color: #fef7ec;
}

.bg-light-purple {
  background-color: #f6f6ff;
}

h1 {
  font-size: 1.9rem;
  text-align: left;
  margin-bottom: 1rem;
  font-family: "Raleway", sans-serif;
}

@media screen and (max-width: 500px) {
  h1 {
    text-align: center;
  }
}

h2 {
  font-size: 1.9rem;
  text-align: left;
  color: #001130;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.63px;
}

h3 {
  font-size: 1.3rem;
  text-align: left;
  color: #001130;
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  letter-spacing: 0;
  margin-bottom: 2rem;
}

h4 {
  font-size: 1.1rem;
  text-align: left;
  font-family: "Roboto", sans-serif;
}

h5 {
  font-size: 1rem;
  text-align: left;
  font-family: "Roboto", sans-serif;
}

h6 {
  font-size: 0.8rem;
  text-align: left;
  font-family: "Roboto", sans-serif;
}

h6 > span {
  color: #f09000;
}

p {
  font-size: 0.7rem;
  color: rgba(104, 104, 104, 0.8);
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.02rem;
  line-height: 18px;
  text-align: justify;
  margin-bottom: 1rem;
}

.screen {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  max-width: 100%;
}

.half-section-container {
  display: flex;
  flex-direction: row;
}
