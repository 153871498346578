.partner-program-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0;
    flex:1;
    margin: 0;
    max-width: 100%;
    /* background-color: red; */
}

#commision-main {
    display: flex;
    flex: 1;
}

#commission-box-one {
    display: flex;
    flex: 1;
}

#commission-box-2 {
    display: flex;
    flex: 1;
}


#commission {
    display: flex;
    /* background-color: blueviolet; */
    height: 700px;
}


#partner-program {
    display: flex;
    height: 760px;
    background-color: #F6F6FF;
}

#how-does-it-work {
    display: flex;
    height: 638px;
    /* background-color: #FFFFFF; */
}

.partner-program-main {
    display: flex;
    flex: 1;
}

.partner-program-box-one {
    display: flex;
    flex: 1;
    background-image: url('../assets/commission_girl_boy.png');
    background-size: 'cover';
    background-repeat: no-repeat;
    background-position: center;
    background-size: 488.51px 488.51px;
}

.how-does-it-work-box-two {
    display: flex;
    flex: 1;
    background-image: url('../assets/boy_girl_with_money.png');
    background-size: 'cover';
    background-repeat: no-repeat;
    background-position: right;
    background-size: 87% 106%;
}

.partner-program-box-two {
    display: flex;
    flex: 1;
    padding-top: 50px;
}

.partner-program-box-two-wrapper {
    margin-left: 50px;
    display: flex;
    flex: 1;
    flex-direction: column;
}

.how-does-it-work-box-two-wrapper {
    margin-left: 50px;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-left: 20%;
    justify-content: center;
}

.shake_hands {
    width: 27.39px;
    height: 26px;
    margin-right: 8px;
}

.partner-program-box-two-tagline {
    display: flex;
    height: 25px;
}

.partner-program-box-two-tagline-span {
    color: #F09000;
    font-size: 25px;
    font-family: Sansation;
    font-weight: 400;
}

.how-does-it-work-box-one-header-span {
    color: #001130;
    font-size: 48px;
    font-family: Raleway;
    line-height: 55px;
    font-weight: 700;
    width: 540px;
}

.partner-program-box-two-header-span {
    color: #001130;
    font-size: 48px;
    font-family: Raleway;
    line-height: 55px;
    font-weight: 700;
    width: 540px;
}

.partner-program-box-two-header {
    display: flex;
    height: 120px;
}

.bullet-1{
    display: flex;
    margin-top: 17px;
}

.partner-program-bullets {
    display: flex;
    flex-direction: column;
}

/* custom ant input number behavior  */
.ant-input-number .ant-input-number-handler-wrap {
    opacity: 1;
}