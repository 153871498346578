.signup-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    width: 10rem;
    border-radius: 30px;
    background-color: #f09000;
    box-shadow: 1px 2px 7px 0 rgba(0, 0, 0, 0.15);
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0.47px;
    line-height: 15px;
    transition: transform 0.5s;
    color: #ffffff;
    margin-right: 0.5rem;
}

.signup-button-alt {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    width: 10rem;
    border-radius: 30px;
    /*border: 2px solid #f0a12b;*/
    box-shadow: 1px 2px 7px 0 rgba(0, 0, 0, 0.15);
    background-color: #fef7ec;
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0.47px;
    line-height: 15px;
    transition: transform 0.5s;
    color: #f09000;
    margin-right: 0.5rem;
}

.signup-button:hover,
.signup-button-alt:hover {
    transform: scale(1.1);
}