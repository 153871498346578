.tagline {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  margin-bottom: 1rem;
  margin-right: 0;
  margin-left: 0;
  max-width: 100%;
}

@media screen and (max-width: 500px) {
  .tagline {
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 1rem;
  }
}

.tagline-desc {
  color: #f09000;
  font-family: "Sansation", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: left;
  text-align: left;
  white-space: nowrap;
  max-width: 100%;
}

@media screen and (max-width: 500px) {
  .tagline-desc {
    flex-wrap: wrap;
  }
}

.tagline-desc > span {
  color: #001130;
}
