.custom-modal-style {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  max-width: 100% !important;
  max-height: 80vh;
}

video {
  max-width: 100%;
  height: auto;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
